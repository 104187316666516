















































































import Vue from 'vue'
import moment from 'moment'

/**  Model **/
import { IRutaModel } from '@/store/types'

/**  Service **/
import RutaService from '@/services/RutaService'
import GaleriaService from '@/services/GaleriaService'

export default Vue.extend({
  name: 'Home',
  data() {
    return {
      num: '+573232486462',
      records: [] as IRutaModel[],
      banners: []
    }
  },

  computed: {
    minHeight(): string {
      const height = this.$vuetify.breakpoint.mdAndUp ? '100%' : '50%'

      return `calc(${height} - ${this.$vuetify.application.top}px)`
    }
  },

  mounted() {
    this.load()
  },

  methods: {
    listaEspera(item: IRutaModel) {
      window.open(
        `https://wa.me/${encodeURIComponent(this.num)}?text=${encodeURIComponent(
          `Hola, me gustaría estar en la lista de espera de la RUTA ${item.Nombre} para el día  ${item.FechaCompleta}, quedo atento si se habilita el cupo, gracias.`
        )}`,
        '_blank'
      )
    },

    getLocation() {
      return location.origin + '/#/'
    },

    calcularCupos(item: IRutaModel) {
      if (!item.Reservas) item.Reservas = []

      const Pendiente = item.Reservas.filter((f) => f.Estado === 'Pendiente').length || 0
      const Confirmada = item.Reservas.filter((f) => f.Estado === 'Confirmada').length || 0

      item.CuposPendientes = Pendiente
      item.CuposConfirmados = Confirmada
      item.CuposDisponibles = item.CuposOfertar - Confirmada

      return item
    },

    async load() {
      this.$store.dispatch('app/showLoading')
      const record = (await RutaService.findAll()) as any
      this.banners = (await GaleriaService.findAll()) as any
      this.records = record.filter((f: IRutaModel) => f.Estado === 'Disponible' && f.Destacada === true && !moment(f.Fecha, 'DD/MM/yyyy').isBefore(moment()))
      this.$store.dispatch('app/hideLoading')
    },

    detail(item: any) {
      this.$router.push(`/catalogo/ruta/${item.Id}`)
    }
  }
})
