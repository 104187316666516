


















































































import Vue from 'vue'
/**  Service **/

/**  Views **/

export default Vue.extend({
  name: 'LayoutAbout',
  data: () => ({
    appTitle: 'ECO Caminantes',
    sidebar: false,
    icons: [
      {
        icon: 'mdi-instagram',
        url: 'https://www.instagram.com/ecocaminantes/'
      },
      {
        icon: 'mdi-facebook',
        url: 'https://www.facebook.com/p/Eco-Caminantes-100063732933031/'
      },
      {
        icon: 'mdi-whatsapp',
        url: 'https://wa.me/+573232486462'
      },
      {
        icon: 'mdi-youtube',
        url: 'https://www.youtube.com/channel/UCXXDb8H6ct2JUAF8ksQo0ew'
      }
    ],
    menuItems: [
      { title: 'Inicio', path: '/', icon: 'home' },
      { title: '¿Quiénes somos?', path: '/quienes-somos', icon: 'lock_open' },
      { title: 'Rutas Eco', path: '/experiencias', icon: 'lock_open' },
      { title: 'Eco Premios', path: '/premios', icon: 'lock_open' }
    ]
  }),

  methods: {
    goToLink(url: string) {
      if (url) window.open(url, '_blank')
    }
  }
})
